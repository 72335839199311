
<template>
  <div class="bigbox">
    <div>
      <van-nav-bar title="按订单开票" left-arrow @click-left="$router.back()"></van-nav-bar>
    </div>
    <div class="orderbox" v-show="!active">
      <div class="order" v-for="(item, index) in fillit" :key="index">
        <div class="flex">
          <div style="margin-left:5%">{{item.carType}}出行</div>
          <div>{{item.orderType}}</div>
        </div>
        <div class="flex2">
          <div style="margin-top:7%;padding-left:10px">
            <van-checkbox
              icon-size="16px"
              @click="change(index,item.id)"
              v-model="item.flag"
              :name="index"
            ></van-checkbox>
          </div>
          <div style=" margin-top:5px;margin-left:5px">
            <div style=" line-height: 20px">
              <img
                style="width:12px;height:12px;color:#eee;margin-top:5px"
                src="../assets/images/appointment.png"
                alt
              />
              <span style="margin-left:10px">{{item.createTime}}</span>
            </div>

            <div class="input" style=" line-height: 20px">
              <span class="yq">11</span>
              <span style="margin-left:7px">{{item.startName}}</span>
            </div>
            <div class="input" style=" line-height: 20px">
              <span class="yellow">11</span>
              <span style="margin-left:7px; width:100px;
              ">{{item.endName}}</span>
            </div>
          </div>

          <div style="margin-top:10px">
            <span style="color:red;">￥{{item.totalFee}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="twobox" v-show="active">
      <div class="two">
        <img style="width:150px" src="../assets/images/noOrder.png" alt />
      </div>
    </div>
    <div class="footer" v-show="active2">
      <div class="top">
        已选择
        <span style="color:red">{{this.num}}</span> 个行程，共
        <span style="color:red">{{this.totalFee.toFixed(2)}}</span>元
      </div>
      <div class="bottom">
        <van-checkbox @click="changeAll" v-model="allFlag" class="all">全选</van-checkbox>
        <!-- <van-button type="primary" @click="checkAll">全选</van-button> -->
        <button @click="go">下一步</button>
      </div>
    </div>
  </div>
</template>

<script>
import { getuser_api } from '../http/api/service'
export default {
  name: 'TaxiH5webInvoice',

  data() {
    return {
      int: 0,
      allsel: false,
      num: '0',
      price: '0.00',
      result: [],
      checked: false,
      radio: '1',
      checkAllFlag: false,
      // 最大选择
      splicList: [],
      carConstance: null,
      statusMap: null,
      totalFee: 0,
      fillit: [],
      allFlag: false,
      ids: [],
      active: false,
      active2: false,
    }
  },

  mounted() {
    var carConstance = new Map()
    carConstance.set('4', '曹操')
    carConstance.set('3', '神州')
    carConstance.set('7', '红旗')
    carConstance.set('11', 'T3')
    carConstance.set('5', '首汽')
    this.carConstance = carConstance
    var statusMap = new Map()
    statusMap.set(1, '实时单')
    statusMap.set(2, '预约订单')
    statusMap.set(3, '接机单')
    statusMap.set(4, '送机单')
    statusMap.set(5, '日租')
    statusMap.set(6, '半日租')
    this.statusMap = statusMap
    this.getuser()
  },

  methods: {
    async getuser() {
      console.log(this.fillit)
      let res = await getuser_api({})
      console.log(res)
      this.fillit = res.data
      this.fillit.forEach((v) => {
        //v.flag=
        v.orderType = this.statusMap.get(v.orderType)
        v.carType = this.carConstance.get(v.carType)
        if (v.totalFee == null) {
          v.totalFee = '0.00'
        }
      })
      if(res.data.length==0){
        this.active=true
    
      }else{
            this.active2=true
        this.active=false

      }
      console.log(this.fillit)
    },
    //下一步
    go() {
      if (this.totalFee !== 0) {
        sessionStorage.setItem('idarr', JSON.stringify(this.ids))
        this.$router.push({
          path: '/Draw',
        })
      }
    },
    change(index, id) {
      console.log(id)
      this.ids.push(id)
      console.log(this.ids)
      let newArr = []
      this.fillit.forEach((v) => {
        console.log(v.flag)
        if (v.flag) {
          newArr.push(v)
        }
      })
      this.num = newArr.length
      let total = 0
      if (newArr.length > 0) {
        newArr.forEach((item) => {
          total = (total * 100 + parseFloat(item.totalFee * 100)) / 100
          console.log(item)
        })
        this.totalFee = total
        sessionStorage.setItem('price', this.totalFee)
        // this.totalFee=newArr.totalFee
        console.log(newArr[0].totalFee)
      } else {
        this.totalFee = 0
      }
      if (this.fillit.length == newArr.length) {
        this.allFlag = true
      } else {
        this.allFlag = false
      }

      //this.fillit[index].flag = !this.fillit[index].flag;
    },
    changeAll() {
      console.log('开始状态', this.allFlag)
      console.log('1111111111')
      let newArr = []

      this.fillit.forEach((v) => {
        this.ids.push(v.id)
        console.log(this.ids)
        v.flag = this.allFlag
      })

      console.log(this.allFlag)
      if (this.allFlag) {
        newArr = this.fillit
      }
      this.num = newArr.length
      let total = 0
      if (newArr.length > 0) {
        newArr.forEach((item) => {
          total = (total * 100 + parseFloat(item.totalFee * 100)) / 100
          console.log(item)
        })
        this.totalFee = total
        sessionStorage.setItem('price', this.totalFee)
        // this.totalFee=newArr.totalFee
        console.log(newArr[0].totalFee)
      } else {
        this.totalFee = 0
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.bigbox {
  background: #eee;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .orderbox {
    margin: 0 15px;
    flex: 1;

    overflow-y: scroll;

    .order {
      margin-top: 10px;
      background: #fff;
      width: 98%;
      margin-left: 1%;
      height: 110px;
      border-radius: 15px;
      .flex {
        display: flex;
        justify-content: space-between;
        margin-left: 20px;
        margin-right: 20px;
        font-size: 15px;
        padding-top: 10px;
      }
      .flex2 {
        display: flex;
        justify-content: space-around;
        margin-right: 20px;
      }
      .input {
        width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .yq {
          width: 10px;
          height: 10px;
          background: #62ce62;
          border-radius: 50%;
          color: #62ce62;
        }
        .yellow {
          width: 20px;
          height: 20px;
          background: #ffb540;
          border-radius: 50%;
          color: #ffb540;
        }
      }
    }
  }
  .twobox {
    width: 100%;
    height: 100%;
    .two {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30%;
    }
  }
  .footer {
    color: #333;
    width: 100%;
    height: 15%;
    background: #ffff;
    .top {
      border-bottom: solid 1px #eee;
      height: 30px;
      line-height: 30px;
      padding-left: 20px;
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      padding-left: 20px;
      button {
        background: #eb4541;
        border: 0;
        color: #fff;
        width: 80px;
        height: 40px;
        margin-top: 10px;
        margin-right: 10px;
      }
    }
  }
}
</style>